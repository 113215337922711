body {
  /* font-family: "Roboto", sans-serif; */
  overflow: hidden;
  background-color: #a3daea57;
}

:disabled {
  cursor: not-allowed;
}

.app {
  background-color: #a3daea57;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: inherit !important;
}

.dms-pages {
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  margin: 0%;
  padding: 0%;
}

.document__table {
  width: 98%;
  margin: 0 auto;
}

.MuiDataGrid-cell:focus,
.MuiTableCell-root:focus-within {
  outline: none !important;
}

.wrap-text {
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
}

.limited-width {
  max-width: 135px;
}
