.symbol-buttons .request-doc-request-icon svg {
  color: #f6bf01;
  font-size: 35px;
  margin-right: 5px;
  cursor: pointer;
}

.symbol-buttons .request-doc-icon:hover .tooltiptext {
  visibility: visible;
}

.symbol-buttons .request-doc-icon .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.747);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transform: translateX(-50%);
  position: absolute;
  z-index: 9;
  top: 34px;
  left: 50%;
  /* margin-left: -60px; */
}

.symbol-buttons .request-doc-icon .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.747) transparent;
}

.symbol-buttons .request-doc-upload-icon svg {
  color: green;
  font-size: 35px;
  cursor: pointer;
}

.simple-select {
  height: 100%;
}