.pdf {
  display: flex;
  flex-direction: column;
  align-content: center;
  max-height: 84vh;
  min-height: 84vh;
  min-width: 80vw;
  max-width: 80vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #212529;
}

/* CANVAS */
/* Container for the canvas element:
   - Sets width to 60% of the viewport width.
   - Allows flexible height up to 170% of the viewport height.
   - Centers the canvas horizontally and aligns it to the top. */
.canvas-container {
  width: 60vw !important;
  height: auto;
  max-height: 170vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* Canvas styling:
   - Limits the canvas width to the container's width.
   - Maintains the canvas aspect ratio.
   - Ensures the canvas is displayed as a block element. */
canvas {
  max-width: 100% !important;
  height: auto !important;
  display: block;
}

/* canvas {
  width: 100% !important;
}

.canvas-container {
  width: 70vw !important;
} */

/* 
.canvas-container {
position: relative;
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
background-color: transparent;
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: transparent;
  border: none;
  touch-action: none;
} */

/* .pdf::-webkit-scrollbar {
  width: 10px;
}

.pdf::-webkit-scrollbar-track {
  background: #c7cdcfe6;
  border-radius: 3px;
}

.pdf::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 3px;
} */

.toolbar {
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #212529;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: space-around;
  padding: 10px 15px;
  box-shadow: 8px 0 8px #000;
}

#pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button:focus {
  outline: 0;
}

.tool {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
}

.select-font-size {
  height: 30px;
  border-radius: 5px;
  padding: 2px;
  outline: none;
}

.icon {
  height: 25px;
  width: 25px;
  color: #fff !important;
}

.toolbar .tool .color-tool {
  height: 25px;
  width: 25px;
  margin: 2px;
  padding: 2px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  display: inline-block;
}

.toolbar .tool .color-tool.active {
  outline: 2px solid white;
  outline-offset: -5px;
}

.toolbar .tool .tool-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.toolbar .tool .tool-button:hover {
  background-color: rgb(98, 106, 120);
  border-radius: 10px;
}

.toolbar .tool .tool-button.active {
  background-color: #424242;
  border-radius: 10px;
}

.color-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 140px;
}

.color-dropdown.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 57px;
}

.color-dropdown-content {
  display: none;
}

.color-dropdown-content.active {
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  width: 140px;
  background-color: #fff;
}

@media only screen and (max-height: 700px) {
  .pdf {
    max-height: 82vh;
    min-height: 82vh;
    min-width: 74vw;
    max-width: 74vw;
  }
}
