.user-container {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - 220px);
  padding: 0px;
  overflow-y: auto;
}

.user-table {
  padding: 0px;
}

.user-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.user-table-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: relative;
  min-height: 52px;
  max-height: 52px;
  padding: 10px;
  background-color: #fff;
  margin: 10px 17px 10px 10px;
}

.user-table-header .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 50vw;
  max-width: 50vw;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.user-table-header .MuiOutlinedInput-root svg {
  width: 35px;
}

/* .user-table-header .MuiInputLabel-root {
  margin-top: -0px;
} */

.user-table-header .MuiOutlinedInput-input {
  margin-left: 4px;
}

.user-table-header label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.user-table-header .Mui-focused {
  margin-top: 0px;
}

.user-table-header .MuiFormLabel-filled {
  margin-top: 0px;
}

.user-page-title {
  display: flex;
  color: #007bff;
  letter-spacing: 0.07rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.add-user {
  letter-spacing: 0.04rem;

  justify-content: flex-end;
  padding: 6px 12px;
  background: #2b93c8d0;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  margin: 5px 11px 0 auto;
}

.user-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 12px;
  padding: 10px;
  padding-top: 3px;
  animation: filter 0.2s ease-in;
}

.hide-user-filter {
  display: none;
}

@keyframes filter {
  from {
    margin-top: -8px;
  }

  to {
    margin-top: 0px;
  }
}

.user-filter-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.user-filter label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.user-filter .Mui-focused {
  margin-top: 0px;
}

.user-filter .MuiOutlinedInput-root {
  min-height: 35px;
  min-width: 154px;
  max-width: 154px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.user-filter .MuiFormLabel-filled {
  margin-top: 0px;
}

.no-user-data {
  margin-left: 10px;
  margin-right: 17px;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  background-color: white;
  text-align: center;
  padding: 10px 0px;
}

.user-table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
  font-size: 0.8rem;
}

.user-pagination {
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  list-style: none;
  cursor: pointer;
}

.user-pagination a {
  padding: 5px 10px;
  /* margin: 0px 7px; */
  /* border-radius: 5px; */
  border: 1px solid #d8d8e4df;
  color: rgba(13, 116, 207, 1);
  background-color: white;
}

.user-pagination a:hover {
  color: white !important;
  background: rgba(13, 116, 207, 1);
}

.user-pagination-link {
  font-weight: bold;
}

.user-pagination-link-active a {
  color: #fff;
  background-color: rgba(13, 116, 207, 1);
}

.user-pagination-link-disabled a {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}

.user-pagination-link-disabled a:hover {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}