@tailwind base;

.dashboard-container {
  min-width: calc(100vw - 220px);
}

.navbar {
  padding: 0px;
}

/* .dashboard-page-content {
  max-height: 95vh;
  overflow-y: scroll;
} */

.dahboard-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 0px 10px;
  gap: 10px;
  margin-top: 10px;
}

.dashboard-column-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard-data {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-width: 48%;
  background: #fff;
}

.box-part-one {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 35%;
}

.box-part-two {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 35%;
}

.dashboard-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  align-content: center;
  background-color: #fff;
  border-radius: 3px;
  min-width: 260px;
  max-width: 260px;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
  background-position: left bottom;
  border: 1px solid #b7b7c88f;
}

.dashboard-stats:hover {
  background-color: #607d8b29;
  transition: 0.3s;
}

.dashboard-stats .data-count {
  display: flex;
  justify-content: center;
  color: #007bff;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1.5rem;
}

.data-with-icon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* color: #666; */
  color: #000000ad;
}

.data-with-icon svg {
  font-size: 20px;
}

.data {
  margin-left: 5px;
  text-align: center;
  font-size: 16px;
  color: #000000ad;
  margin-top: 2px;
  font-weight: 600;
  letter-spacing: 0.07rem;
}

.dashboard-column-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 0px 10px;
  padding: 0px 0px;
  margin-top: 10px;
  /* background: #fff; */
}

.linechart {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0px;
  padding: 0px 10px;
  margin-top: 0px;
  min-width: 49.5%;
  max-width: 49.5%;
}

.linechart canvas {
  height: 100%;
}

.piechart {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0px;
  padding: 10px 30px;
  margin-top: 0px;
  min-width: 49.5%;
  max-width: 49.5%;
}

.dashboard-column-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  max-height: 40vh;
  padding: 10px 10px;
  background: #fff;
  border-radius: 0;
  margin: 12px;
}

.dashboard-page-content .dashboard-column-3 #welcome {
  background: var(--nbl-primary);
  border-radius: 5px;
  color: #fff;
  background: #2b93c8d0;
  padding: 15px 20px;
  width: 100%;
  min-height: 38vh;
  max-height: 38vh;
}

#welcome p.fullDate {
  padding-top: 0.4rem;
}

.dashboard-page-content p.greetings {
  font-size: 24px;
  font-weight: 600;
}

.documentlist {
  width: 49.5%;
  background: #fff;
  padding: 40px;
}

.documentlist ul {
  list-style: none;
}

.notification-section-one {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 5px 10px;
  min-width: 48%;
  min-height: 325px;
  background: #fff;
}

.notification-section-two {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 6px 12px;
  min-width: 51%;
  min-height: 325px;
  background: #fff;
}

.notification-title {
  width: 189px;
  border-bottom: 3px solid #007bff;
  color: #007bff;
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.08rem;
}

.notification-title-requests {
  width: fit-content;
  border-bottom: 3px solid #f6bf01;
  color: #f6bf01;
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.08rem;
}

.notification-title-recent {
  width: fit-content;
  border-bottom: 3px solid #008000;
  color: #008000;
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.08rem;
}

.notification-container {
  /* margin: 20px 0px; */
  height: 300px;
  overflow-y: auto;
}

.notification-container::-webkit-scrollbar {
  width: 8px;
}

.notification-container::-webkit-scrollbar-track {
  background: #fff;
}

.notification-container::-webkit-scrollbar-thumb {
  background-color: #e3e3e3cc;
}

.latest-notification {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  /* width: 90%; */
  overflow-wrap: break-word;
  border-bottom: 1px solid rgba(189, 189, 189, 0.848);
  cursor: default;
}

.latest-notification svg {
  cursor: default;
  font-size: 2rem;
  /* margin-top: -2px; */
}

.latest-notification:hover {
  background-color: rgba(232, 231, 231, 0.848);
}

.notification-details {
  font-size: 0.92rem;
  padding-left: 8px;
  letter-spacing: 0.06rem;
}

@media screen and (max-width: 768px) {
  /* .dashboard-page-content .dashboard-column-3 #welcome {
    grid-column: span 12;
  } */

  #welcome>div {
    display: block !important;
  }

  .dashboard-page-content p.greetings {
    margin-bottom: 0.4rem;
  }
}

@media screen and (max-width: 1200px) {
  /* .dashboard-page-content .dashboard-column-3 #welcome {
    grid-column: span 12;
  } */
}

/*Dashboard BarChart*/
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 44%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --chart-1: 221.2 83.2% 53.3%;
    --chart-2: 212 95% 68%;
    --chart-3: 216 92% 60%;
    --chart-4: 210 98% 78%;
    --chart-5: 212 97% 87%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 210 40% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 221.2 83.2% 53.3%;
    --chart-1: 221.2 83.2% 53.3%;
    --chart-2: 212 95% 68%;
    --chart-3: 216 92% 60%;
    --chart-4: 210 98% 78%;
    --chart-5: 212 97% 87%;
  }
}

/* DashboardProto */
.boxshadow {
  box-shadow: 0px 1px 4px #007bff;
}

.letterSpacing {
  letter-spacing: 0.03rem;
}

/* Layout styles */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.navbar {
  /* Add any specific navbar styling here */
}

.document-table {
  width: 100%;
  padding: 2rem 1.5rem;
}

/* Header and Greeting Styles */
.greeting-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 1.5rem;
  background-color: #007bff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.greeting-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.greeting-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #001933;
}

.greeting-date {
  font-family: monospace;
  background-color: #001933;
  color: #e6f2ff;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.greeting-msg {
  font-size: 1rem;
  color: #001933;
  font-style: italic;
}

/* Card Styles */
.card {
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
}

.card:hover {
  background-color: #e2efff;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.card-title {
  font-size: 0.875rem;
  font-weight: 500;
}

.card-content {
  font-size: 2rem;
  font-weight: 600;
}

.card-subtext {
  font-size: 0.75rem;
  color: #666;
}

/* Shortcut Button Styles */
.shortcut-btn {
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6f2ff;
  color: #007bff;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, transform 0.2s;
}

.shortcut-btn:hover {
  background-color: #cce5ff;
  transform: scale(1.05);
  color: black;
}

.shortcut-label {
  margin-top: 0.25rem;
  font-size: 0.625rem;
  font-weight: 500;
  text-align: center;
}
